var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "background", style: { height: _vm.height } },
    [
      _c("zg-image", {
        class: { mirror: _vm.mirrorImage },
        attrs: { src: _vm.image.src, sources: _vm.image.sources },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }