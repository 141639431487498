<template>
  <div
    class="background"
    :style="{ height: height }"
  >
    <zg-image
      :src="image.src"
      :sources="image.sources"
      :class="{ 'mirror': mirrorImage }"
    />
  </div>
</template>

<script>

  export default {
    name: 'BrokerHeroBackground',
    components: {
      ZgImage: () => import(/* webpackChunkName: 'zc/zg-image' */
        '@zc/components/ZgImage/ZgImage.vue')
    },
    props: {
      image: {
        type: Object,
        required: false,
        default: () => {},
        validator: value => (value.src && value.sources)
      }
    },
    data: () => ({
      height: null
    }),
    computed: {
      mirrorImage () {
        return this.FINLAND
      }
    },
    mounted () {
      setTimeout(() => {
        this.height = `${this.$el.clientHeight}px`
      }, 3000)
    }
  }
</script>

<style lang="scss" scoped>
  .background {
    display: none;
    position: absolute;
    inset: 0;
    z-index: 1;
    overflow: hidden;

    @include mq(medium) {
      display: block;
    }

    :deep(picture) {
      overflow: hidden;
      width: 100%;
      height: 100%;
    }

    :deep(img) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    :deep(picture).mirror img {
      transform: scaleX(-1);
    }
  }
</style>
